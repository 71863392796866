// Colours
$primary: #1DB954;
$secondary: #E7E9EB;

$yellow: #F4C51B;

$danger: #FF453A;
$success: #33C759;

$text: #242423;

$light-grey: #F4F4F4;
$mid-grey: #E0E0E0;
$dark-grey: #BBBBBB;


// Sizes
$mobile-width: 700px;
$large-width: 1600px;