// Node module styles
@import "~animate.css/animate.css";
@import "~react-toggle/style.css";
@import "~rodal/lib/rodal.css";
@import "~noty/lib/noty.css";

// Custom scss files
@import "vars";
@import "src/styles/typography";
@import "src/styles/animations";
@import "src/styles/overrides";
@import "src/styles/utils/index";

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100vw;
  overflow-x: hidden;
  overflow-y: overlay;
}

body,
html,
#root {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 100vh;
}

* {
  outline-color: $text;
  box-sizing: border-box;
}

[data-whatintent="mouse"] *:focus,
[data-whatintent="touch"] *:focus {
  outline: none;
}
